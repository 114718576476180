<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">Copyright &copy; {{new Date().getFullYear()}} Vision Skill. All Rights Reserved. Vision Skill® is a registered trademark.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
