<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <h6 class="mr-2">Welcome, Admin</h6>
        <div class="c-avatar">
          <img src="@/assets/avatars/1.png" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>    
      <CDropdownItem
                    :to="{
                      name: 'EditUser',
                      params: { id: '606ed2c58622418a2b994b2c' },
                    }"
                  >      
      <CIcon name="cil-lock-locked" /> Change Password
     </CDropdownItem>    
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
    };
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then((res) => {
        this.$router.push("/");
      });
    },
//      toChangeAdminPassword: function() {
//     this.$router.push({
//     name: 'EditAdmin', 
//     params: {id: '606ed2c58622418a2b994b2c' }
         
   
// }) 
    
//     },
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/login");
          });
        }
        throw err;
      });
    });
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
