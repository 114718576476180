export default [
  {
    _name: "CSidebarNav",
    _children: [    
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: { name: "Dashboard" },
        icon: "cil-cursor",
      },  
      {
        _name: "CSidebarNavItem",
        name: "Users",
        to: { name: "User List" },
        icon: "cil-cursor",
      },
      {
        _name: "CSidebarNavItem",
        name: "Vocabulary",
        to: { name: "Vocabulary List" },
        icon: "cil-cursor",
      },
    ],
  },
];
